<template>
    <v-container fluid>
        <v-alert
        dense
        outlined
        type="error"
        v-if="Object.keys(validation_errors).length > 0"
        >
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form ref="promotionForm" class="custom-form" @submit.prevent="savePromotion">
            <v-row dense>
                <v-col cols="12" 
                    md="3"
                    style="height: 272px"
                >
                    <v-combobox
                        label="Employee"
                        dense
                        outlined
                        hide-details
                        :items="employees"
                        v-model="selectedEmployee"
                        item-text="display_name"
                        item-value="id"
                        class="mb-1"
                        @focus="getEmployees"
                        :rules="[() => !!promotion.employee_id || '']"
                        :error-messages="validation_errors.employee_id"
                    ></v-combobox>
                    <v-card :dark="selectedEmployee ? true : false" :color="selectedEmployee ? 'primary' : '#ECEFF1'" :elevation="1">
                        <v-card-text class="text-center pa-2">
                            <v-avatar
                                color="deep-purple darken-4"
                                size="120"
                                class="mb-4"
                            >
                                <v-img v-if="selectedEmployee" :src="$store.state.host+selectedEmployee.image"></v-img>
                                <v-img v-else src=""></v-img>
                            </v-avatar>
                            <h2 class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.emp_id : 'Employee ID' }}
                            </h2>
                            <h1 class="subtitle-2">
                                {{ selectedEmployee ? selectedEmployee.name : 'Employee name'}}
                            </h1>
                            <p class="caption mb-0">
                                {{ selectedEmployee && selectedEmployee.designation ? selectedEmployee.designation.name : 'Designation' }}, 
                                {{ selectedEmployee && selectedEmployee.department ? selectedEmployee.department.name : 'Department' }}
                            </p>
                            <p class="caption mb-0">
                                {{ selectedEmployee ? selectedEmployee.phone : 'Phone' }}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                >
                    <v-card
                       height="100%" 
                       :elevation="1"
                    >
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Promoted To</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Department</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-combobox
                                        label="Department"
                                        dense
                                        outlined
                                        hide-details
                                        :items="$store.getters['department/departments']"
                                        v-model="selectedDepartment"
                                        item-text="name"
                                        item-value="id"
                                        :loading="$store.getters['department/loadingDepartments']"
                                        @focus="$store.dispatch('department/getDepartments')"
                                        class="custom-form"
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row class="py-2">
                                <v-col cols="4" class="py-1 caption">Designation</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-combobox
                                        label="Designation"
                                        dense
                                        outlined
                                        hide-details
                                        :items="$store.getters['designation/designations']"
                                        v-model="selectedDesignation"
                                        item-text="name"
                                        item-value="id"
                                        :loading="$store.getters['designation/loadingDesignations']"
                                        @focus="$store.dispatch('designation/getDesignations')"
                                        class="custom-form"
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Promoted By</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-combobox
                                        label="Promoted By"
                                        dense
                                        outlined
                                        hide-details
                                        :items="employees"
                                        v-model="selectedPromotedBy"
                                        item-text="display_name"
                                        item-value="id"
                                        class="custom-form"
                                        @focus="getEmployees"
                                        :rules="[() => !!promotion.promoted_by || '']"
                                        :error-messages="validation_errors.promoted_by"
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col 
                    cols="12"
                    md="5"
                >
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Promotion Information</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Promotion Date</v-col>
                                <v-col cols="8" class="py-0">
                                   
                                    <v-menu 
                                        max-width="290"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                dense
                                                readonly
                                                outlined
                                                hide-details
                                                v-on="on"
                                                v-model="promotion.promotion_date"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            no-title
                                            v-model="promotion.promotion_date"
                                            @input="menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Remark</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-textarea
                                        dense
                                        outlined
                                        v-model="promotion.remark"
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" class="text-right">
                    <v-btn height="30px" dark color="light-blue darken-2 mr-1" :elevation="1" :loading="loading" type="submit">Save</v-btn>
                    <v-btn height="30px" dark color="deep-orange" :elevation="1" @click="resetForm">Cancel</v-btn>
                </v-col>
            </v-row>
        </v-form>

        <v-row v-if="promotions.length > 0">
            <v-col cols="12">
                <v-btn
                    color="primary"
                    class="white--text"
                    small
                    dense
                    @click="print"
                >
                    <v-icon left small dense> mdi-printer </v-icon>
                    Print
                </v-btn>
            </v-col>
            <v-col cols="12" class="pb-0" id="reportContent">
                <v-simple-table dense class="record_table">
                    <thead>
                        <tr class="record_heading_tr">
                            <th>SL.</th>
                            <th>Date</th>
                            <th>Department</th>
                            <th>Previous Department</th>
                            <th>Designation</th>
                            <th>Previous Designation</th>
                            <th>Promoted By</th>
                            <th>Remark</th>
                            <th>Option</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(record, sl) in promotions" :key="sl">
                            <td>{{++sl}}</td>
                            <td>{{record.promotion_date}}</td>
                            <td style="text-align: left !important;">{{record.department_name}}</td>
                            <td style="text-align: left !important;">{{record.previous_department_name}}</td>
                            <td style="text-align: left !important;">{{record.designation_name}}</td>
                            <td style="text-align: left !important;">{{record.previous_designation_name}}</td>
                            <td style="text-align: left !important;">{{record.promoted_by_name}}</td>
                            <td style="text-align: left !important;">{{record.remark}}</td>
                            <td>
                                <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            @click="editPromotion(record)"
                                            color="primary"
                                            v-on="on"
                                            >mdi-circle-edit-outline</v-icon
                                        >
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            small
                                            @click="deletePromotion(record)"
                                            color="error"
                                            v-on="on"
                                            >mdi-delete-circle-outline</v-icon
                                        >
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data(){
        return {
            employees: [],
            selectedEmployee: null,
            selectedDepartment: null,
            selectedDesignation: null,
            selectedPromotedBy: null,
            validation_errors : {},
            promotion: {
                id: null,
                remark: '',
                employee_id: null,
                department_id: null,
                designation_id: null,
                promoted_by: null,
                promotion_date: this.toISOLocal().substr(0, 10),
            },
            promotions: [],
            menu: false,
            loading: false,
        }
    },
    watch: {
        selectedEmployee(e){
            this.promotion.employee_id = null;
            this.getPromotions();
            if(!e || e.id == undefined){
                return;
            }
            this.promotion.employee_id = e.id;
        },
        selectedPromotedBy(e){
            this.promotion.promoted_by = null;
            if(!e || e.id == undefined){
                return;
            }
            this.promotion.promoted_by = e.id;
        },
        selectedDepartment(e){
            this.promotion.department_id = null;
            if(!e || e.id == undefined){
                return;
            }
            this.promotion.department_id = e.id;
        },
        selectedDesignation(e){
            this.promotion.designation_id = null;
            if(!e || e.id == undefined){
                return;
            }
            this.promotion.designation_id = e.id;
        },
    },
    methods: {
        async getPromotions(){
            this.promotions = [];
            if(this.selectedEmployee && this.selectedEmployee.id != undefined){
                this.promotions = await this.$store.dispatch('promotion/getPromotions',{
                    apiParams: {
                        employee_id: this.selectedEmployee.id
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
        },
        async getEmployees(){
            this.employees = await this.$store.dispatch('employee/getEmployees', {
                apiParams: {},
                options: {
                    returnData: true
                }
            });
        },
        async savePromotion(){

            if(!this.$refs.promotionForm.validate()){
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            if(this.promotion.department_id == null && this.promotion.designation_id == null){
                alert('Atleast one required from department and designation');
                return;
            }
            this.loading = true;
            let url = "/add_promotion";
            if(this.promotion.id){
                url = '/update_promotion';
            }

            let data = {
                url,
                promotion: this.promotion
            }

            let resObj = await this.$store.dispatch('promotion/savePromotion', data);

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;
            if(resObj.success) {
                this.resetForm();
            }
        },
        editPromotion(promotion){

            this.selectedDepartment = {
                id : promotion.department_id,
                name: promotion.department_name,
            }
            this.selectedDesignation = {
                id : promotion.designation_id,
                name: promotion.designation_name,
            }
            this.selectedPromotedBy = {
                id : promotion.promoted_by,
                display_name: promotion.promoted_by_name,
            }

            this.promotion = {
                id                      : promotion.id,
                remark                  : promotion.remark,
                employee_id             : promotion.employee_id,
                department_id           : promotion.department_id,
                designation_id          : promotion.designation_id,
                promoted_by             : promotion.promoted_by,
                promotion_date          : promotion.promotion_date,
                previous_employee_id    : promotion.employee_id,
                previous_department_id  : promotion.previous_department_id,
                previous_designation_id : promotion.previous_designation_id,
            }
        },
        async deletePromotion(promotion){
            if (!confirm("Are you sure?")) return;
            await this.$store.dispatch("promotion/deletePromotion", promotion.id);
            await this.getPromotions();
        },
        resetForm(){
            this.selectedEmployee = null;
            this.selectedDepartment = null;
            this.selectedDesignation = null;
            this.selectedPromotedBy = null;

            this.validation_errors = {};

            this.promotion = {
                id: null,
                remark: '',
                employee_id: null,
                department_id: null,
                designation_id: null,
                promoted_by: null,
                promotion_date: this.toISOLocal().substr(0, 10),
            };

            this.$refs.promotionForm.resetValidation();
        },
        async print(){
            let title = 'Promotion Record of ';
            if(this.selectedEmployee && this.selectedEmployee.id != undefined){
                title += this.selectedEmployee.name;
            }

            let company_profile = this.$store.getters['company/company'];

            // Get HTML to print from element
            const prtHtml = document.getElementById('reportContent').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                    <div class="container container--fluid">
                        <div class="row row--dense">
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+company_profile.logo}">
                            </div>
                            <div class="col-8 px-2 text-center">
                                <span class="font-weight-black">
                                    ${company_profile.name}
                                </span>
                                <br>
                                <span>${company_profile.address}</span>
                                <br>
                                <span>
                                    Email:${company_profile.email}, Contact:${company_profile.phone}
                                </span>
                            </div>
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+'linkup.png'}">
                            </div>
                            <div class="col-12 px-2 text-center">
                                <p class="font-weight-black custom_header">
                                    ${title}
                                </p>
                            </div>
                            <div class="col col-12 px-2">
                                ${prtHtml}
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`);

            WinPrint.document.head.innerHTML += `
                <style>
                    .v-data-table__wrapper{
                        overflow: unset;
                    }
                    
                    .record_heading_tr th{
                        text-align: center !important;
                    }
                    .custom_header{
                        background-color: #0000001f;
                        border-radius: 4px;
                        margin-bottom: 5px !important;
                    }
                    tr:hover{
                        background: unset !important;
                    }
                    .record_heading_tr:hover{
                        background: #4caf7773 !important;
                    }
                    @media print {
                        table.record_table { page-break-after:auto }
                        table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table thead { display:table-header-group }
                        table.record_table tfoot { display:table-footer-group }
                    }
                </style>
            `;

            let rows = WinPrint.document.querySelectorAll('.record_table tr');
            rows.forEach(row => {
                row.lastChild.remove();
            })

            WinPrint.focus();
            WinPrint.document.close();
            await new Promise(resolve => setTimeout(resolve, 2000));
            WinPrint.print();
            WinPrint.close();
        }
    },
}
</script>

<style>
.record_table{
    margin-bottom: 5px;
}
.record_table table, .record_table th, .record_table td {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    padding: 0 !important;
    
}
.record_table th, .record_table td {
    padding: 2px !important;
    font-size: 11px !important;
    text-align: center !important;
    height: 0 !important;
}
.record_heading_tr{
    background: #4caf7773;
}
</style>